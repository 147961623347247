import ru from './ru.json';
import kg from './fnp-front.json'
import {createI18n} from 'vue-i18n';

let locale;

if(localStorage.getItem('language')){
  locale=localStorage.getItem('language')
}else{
  locale='ru'
}
const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'ru',
    messages: {
      ru:ru,
      kg:kg
    }
  })

export default i18n;