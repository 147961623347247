import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../src/assets/css/fontawesome-all.min.css";
import "animate.css/animate.min.css";
import "../src/assets/scss/style.scss";
import "swiper/css";
import "swiper/css/effect-fade";
import Notifications from "@kyvg/vue3-notification";
import PrimeVue from "primevue/config";
createApp(App).use(i18n).use(router).use(Notifications).use(PrimeVue).mount("#app");
